import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
// @ts-ignore: @reach/router types
import { Link } from '@reach/router';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { SvgIcon } from '@material-ui/core';
var currentRouteStyle = function (isCurrent) { return ({
    borderRadius: 12,
    backgroundColor: isCurrent ? palette.white : 'initial',
    color: isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    padding: '8px 12px',
    margin: 0,
}); };
var baseRoutesToHighlight = ['/api/v2/active-protection', '/api/v2/security', '/api/v2/inventory'];
var NavigationItem = function (_a) {
    var label = _a.label, to = _a.to, Icon = _a.Icon, _b = _a.isSvg, isSvg = _b === void 0 ? false : _b;
    var _c = useState(false), isCurrent = _c[0], setCurrent = _c[1];
    return (React.createElement(Link, { to: to, getProps: function (_a) {
            var href = _a.href, isPartiallyCurrent = _a.isPartiallyCurrent, isCurrent = _a.isCurrent;
            if (baseRoutesToHighlight.includes(href) && isPartiallyCurrent) {
                setCurrent(true);
            }
            else {
                setCurrent(isCurrent);
            }
        } }, !Icon ? (React.createElement(Text, { variant: "titleS", style: currentRouteStyle(isCurrent) }, label)) : (React.createElement(Box, null, isSvg ? (React.createElement("div", { style: currentRouteStyle(isCurrent) },
        React.createElement(SvgIcon, null, Icon))) : (React.createElement(Box, { style: currentRouteStyle(isCurrent) },
        React.createElement(Icon, null)))))));
};
export default NavigationItem;
