// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import React from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import Box from '@material-ui/core/Box';
import GavelIcon from '@material-ui/icons/Gavel';
import NavigationItem from './NavigationItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
var Navigation = function () {
    var location = useLocation();
    if (!location.pathname.match(/api\/v2/))
        return null;
    return (React.createElement(Box, { display: "flex", justifyContent: "space-between", style: {
            background: '#F4F5FC',
            padding: 24,
        } },
        React.createElement(Box, { display: "grid", alignItems: "center", gridTemplateColumns: "repeat(7, auto)", gridGap: 12, style: {
                background: '#F4F5FC',
            } },
            React.createElement(Link, { to: '/dashboard' },
                React.createElement("svg", { width: "45", height: "45", viewBox: "0 0 45 45", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M45 35.7895C45 40.8772 40.8745 45 35.7835 45H9.21651C4.12549 45 0 40.8772 0 35.7895V9.23976C0 4.15204 4.12549 0 9.21651 0H35.7835C40.8745 0 45 4.15204 45 9.23976V35.7895Z", fill: "#123770" }),
                    React.createElement("path", { d: "M31.7165 19.0059V24.7953C31.7165 26.3743 32.2724 27.7486 33.4135 28.8889C34.5546 30 35.9005 30.5556 37.5098 30.5556V34.8831C34.7009 34.8831 32.331 33.9182 30.3414 31.9299C28.381 29.9708 27.3862 27.6024 27.3862 24.7953V8.91815H31.7165V14.6784H37.5098V19.0059H31.7165Z", fill: "white" }),
                    React.createElement("path", { d: "M18.4915 28.8597C19.6326 27.7486 20.1885 26.3743 20.1885 24.7953C20.1885 23.1872 19.6326 21.8421 18.4915 20.7018C17.3797 19.5907 16.0045 19.0059 14.4246 19.0059C12.8153 19.0059 11.4694 19.5907 10.3283 20.7018C9.2165 21.8421 8.63133 23.1872 8.63133 24.7953C8.63133 26.3743 9.2165 27.7486 10.3283 28.8597C11.4694 30 12.8153 30.5556 14.4246 30.5556C16.0045 30.5556 17.3797 30 18.4915 28.8597ZM24.5188 24.7953C24.5188 27.6024 23.5533 29.9708 21.5637 31.9299C19.6034 33.9182 17.2041 34.8831 14.4246 34.8831C11.6157 34.8831 9.24576 33.9182 7.25616 31.9299C5.29583 29.9708 4.30103 27.6024 4.30103 24.7953C4.30103 21.9883 5.29583 19.6199 7.25616 17.6316C9.24576 15.6725 11.6157 14.6784 14.4246 14.6784C16.5897 14.6784 18.5208 15.2924 20.1885 16.462V8.91815H24.5188V24.7953Z", fill: "white" }))),
            React.createElement("svg", { width: "45", height: "45", viewBox: "0 0 45 45", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("g", { clipPath: "url(#clip0_34_2308)" },
                    React.createElement("path", { d: "M45 35.7722C45.0004 36.9841 44.7619 38.1842 44.2983 39.304C43.8347 40.4237 43.155 41.4411 42.2981 42.2981C41.4411 43.155 40.4237 43.8347 39.304 44.2983C38.1842 44.7619 36.9841 45.0004 35.7722 45H9.22781C6.78045 45 4.43331 44.0278 2.70276 42.2972C0.972213 40.5667 0 38.2196 0 35.7722L0 9.225C0.000745773 6.77812 0.973288 4.43171 2.70376 2.70177C4.43423 0.971828 6.78093 3.15124e-07 9.22781 4.28774e-07H35.7722C36.9839 -0.000368986 38.1838 0.23797 39.3033 0.701404C40.4229 1.16484 41.4401 1.84429 42.2971 2.70095C43.154 3.5576 43.8337 4.57468 44.2975 5.6941C44.7613 6.81351 45 8.01332 45 9.225V35.7722Z", fill: "white" }),
                    React.createElement("g", { clipPath: "url(#clip1_34_2308)" },
                        React.createElement("path", { d: "M42 26.2076C42.0003 27.231 41.799 28.2444 41.4075 29.19C41.016 30.1356 40.442 30.9947 39.7184 31.7184C38.9947 32.442 38.1356 33.016 37.19 33.4075C36.2444 33.799 35.231 34.0003 34.2076 34H11.7924C9.72571 34 7.74369 33.179 6.28233 31.7177C4.82098 30.2563 4 28.2743 4 26.2076V3.79C4.00063 1.72374 4.82189 -0.257665 6.28317 -1.71851C7.74446 -3.17935 9.72612 -4 11.7924 -4H34.2076C35.2308 -4.00031 36.2441 -3.79905 37.1895 -3.4077C38.1349 -3.01636 38.9939 -2.4426 39.7175 -1.7192C40.4411 -0.995802 41.0152 -0.136933 41.4068 0.808348C41.7984 1.75363 42 2.7668 42 3.79V26.2076Z", fill: "white" }),
                        React.createElement("path", { d: "M28.2797 20.9375L23.4751 14.886V9.01977C24.1637 8.90082 24.7827 8.52781 25.2096 7.97448C25.6364 7.42116 25.8402 6.7278 25.7805 6.0315C25.7208 5.3352 25.402 4.68664 24.8872 4.21405C24.3723 3.74146 23.6989 3.47925 23.0001 3.47925C22.3012 3.47925 21.6278 3.74146 21.1129 4.21405C20.5981 4.68664 20.2793 5.3352 20.2196 6.0315C20.1599 6.7278 20.3637 7.42116 20.7906 7.97448C21.2174 8.52781 21.8364 8.90082 22.5251 9.01977V14.8932L17.6492 20.9375H12.6617V26.5188H18.2501V21.707L23.0001 15.8147L27.7501 21.7973V26.5188H33.3313V20.9375H28.2797Z", fill: "#123770" })),
                    React.createElement("path", { d: "M13.614 39C13.548 39 13.493 38.978 13.449 38.934C13.405 38.8827 13.383 38.8277 13.383 38.769C13.383 38.7323 13.3866 38.6993 13.394 38.67L16.012 31.597C16.034 31.5163 16.078 31.4467 16.144 31.388C16.21 31.3293 16.3016 31.3 16.419 31.3H17.827C17.9443 31.3 18.036 31.3293 18.102 31.388C18.168 31.4467 18.212 31.5163 18.234 31.597L20.852 38.67C20.8593 38.6993 20.863 38.7323 20.863 38.769C20.863 38.8277 20.841 38.8827 20.797 38.934C20.753 38.978 20.698 39 20.632 39H19.488C19.378 39 19.2973 38.9743 19.246 38.923C19.1946 38.8717 19.1616 38.824 19.147 38.78L18.674 37.548H15.572L15.099 38.78C15.0843 38.824 15.0513 38.8717 15 38.923C14.9486 38.9743 14.868 39 14.758 39H13.614ZM15.979 36.129H18.256L17.123 32.961L15.979 36.129ZM22.0851 39C22.0044 39 21.9384 38.9743 21.8871 38.923C21.8358 38.8717 21.8101 38.8057 21.8101 38.725V31.575C21.8101 31.4943 21.8358 31.4283 21.8871 31.377C21.9384 31.3257 22.0044 31.3 22.0851 31.3H25.0881C25.6821 31.3 26.1954 31.3917 26.6281 31.575C27.0681 31.7583 27.4091 32.037 27.6511 32.411C27.9004 32.7777 28.0251 33.236 28.0251 33.786C28.0251 34.3433 27.9004 34.8053 27.6511 35.172C27.4091 35.5313 27.0681 35.799 26.6281 35.975C26.1954 36.151 25.6821 36.239 25.0881 36.239H23.6141V38.725C23.6141 38.8057 23.5884 38.8717 23.5371 38.923C23.4858 38.9743 23.4198 39 23.3391 39H22.0851ZM23.5811 34.875H25.0331C25.4071 34.875 25.6968 34.7833 25.9021 34.6C26.1148 34.4167 26.2211 34.1417 26.2211 33.775C26.2211 33.4523 26.1258 33.1883 25.9351 32.983C25.7518 32.7777 25.4511 32.675 25.0331 32.675H23.5811V34.875ZM29.422 39C29.3413 39 29.2753 38.9743 29.224 38.923C29.1727 38.8717 29.147 38.8057 29.147 38.725V31.575C29.147 31.4943 29.1727 31.4283 29.224 31.377C29.2753 31.3257 29.3413 31.3 29.422 31.3H30.665C30.7457 31.3 30.8117 31.3257 30.863 31.377C30.9143 31.4283 30.94 31.4943 30.94 31.575V38.725C30.94 38.8057 30.9143 38.8717 30.863 38.923C30.8117 38.9743 30.7457 39 30.665 39H29.422Z", fill: "#123770" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0_34_2308" },
                        React.createElement("rect", { width: "45", height: "45", fill: "white" })),
                    React.createElement("clipPath", { id: "clip1_34_2308" },
                        React.createElement("rect", { width: "38", height: "38", fill: "white", transform: "translate(4 -4)" })))),
            React.createElement(NavigationItem, { to: "/api/v2/apps", label: "Apps", Icon: AppsIcon }),
            React.createElement(NavigationItem, { to: "/api/v2/dashboard", label: "Dashboard" }),
            React.createElement(NavigationItem, { to: "/api/v2/inventory/apis", label: "Inventory" }),
            React.createElement(NavigationItem, { to: "/api/v2/security/hack-me-amadeus", label: "Security" }),
            React.createElement(NavigationItem, { to: "/api/v2/active-protection/overview", label: "Active Protection" })),
        React.createElement(Box, { display: "grid", alignItems: "center", gridTemplateColumns: "repeat(5, 1fr)", gridGap: 12, style: {
                justifySelf: 'flex-end',
            } },
            React.createElement(NavigationItem, { to: "/api/v2/policies", label: "Policies", Icon: GavelIcon }),
            React.createElement(NavigationItem, { to: "/api/v2/notifications", label: "Notifications", Icon: NotificationsIcon }),
            React.createElement(NavigationItem, { to: "/devsecops/stanford-dish-security-university", label: "Security Training", Icon: React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M3.90039 9.30078H9.70117V7.85058H3.90039V9.30078Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M9.70117 12.2012H3.90039V10.751H9.70117V12.2012Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M9.70117 15.1016H3.90039V13.6514H9.70117V15.1016Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M19.8525 9.30078H14.0518V7.85058H19.8525V9.30078Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M14.0518 12.2012H19.8525V10.751H14.0518V12.2012Z", fill: "currentColor" }),
                    React.createElement("path", { d: "M14.0518 15.1016H19.8525V13.6514H14.0518V15.1016Z", fill: "currentColor" }),
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.7251 3.5C1.32464 3.5 1 3.82464 1 4.2251V18.0019C1 18.4024 1.32464 18.727 1.7251 18.727H9.70117C10.5021 18.727 11.1514 19.3763 11.1514 20.1772C11.1514 20.5777 11.476 20.9023 11.8765 20.9023C12.2769 20.9023 12.6016 20.5777 12.6016 20.1772C12.6016 19.3763 13.2508 18.727 14.0518 18.727H22.0278C22.4283 18.727 22.7529 18.4024 22.7529 18.0019V4.2251C22.7529 3.82464 22.4283 3.5 22.0278 3.5H14.0518C13.1855 3.5 12.4079 3.87977 11.8765 4.48191C11.345 3.87977 10.5674 3.5 9.70117 3.5H1.7251ZM11.1514 6.40039V17.6649C10.7248 17.4181 10.2295 17.2769 9.70117 17.2769H2.45019V4.95019H9.70117C10.5021 4.95019 11.1514 5.59947 11.1514 6.40039ZM14.0518 17.2769C13.5235 17.2769 13.0282 17.4181 12.6016 17.6649V6.40039C12.6016 5.59947 13.2508 4.95019 14.0518 4.95019H21.3027V17.2769H14.0518Z", fill: "currentColor" })), isSvg: true }),
            React.createElement(NavigationItem, { to: "/api/v2/devsecops", label: "DevSecOps", Icon: TrackChangesIcon }),
            React.createElement(NavigationItem, { to: "/api/v2/settings", label: "Settings", Icon: SettingsIcon }))));
};
export default Navigation;
